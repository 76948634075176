import { createApp } from "vue";
import VuePannellum from "vue-pannellum";
import App from "./App.vue";
import "./index.css";
import VueDragscroll from "vue-dragscroll";
import VueFullscreen from "vue-fullscreen";

const app = createApp(App);
app.component("VPannellum", VuePannellum);
app.use(VueDragscroll);
app.use(VueFullscreen);
app.mount("#app");
